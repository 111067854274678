'use client'
import axios, { AxiosError } from 'axios'
import { Formik } from 'formik'
import { signIn, useSession } from 'next-auth/react'
import { useRouter, useSearchParams } from 'next/navigation'
import { useState } from 'react'
import * as Yup from 'yup'
import { GetProviderType } from '@/app/(protected)/api/providers/route'
import Link from 'next/link'

const FormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required(),
})

export default function UserSignInForm() {
  const [error, setError] = useState<string | null>(null)
  const [message, setMessage] = useState<string | null>(null)
  const searchParams = useSearchParams()
  const callbackUrl = searchParams?.get('callbackURL')
  const accountId = searchParams?.get('act')

  const router = useRouter()
  const { data } = useSession()

  const handlePassageSignIn = () => {
    signIn('passage')
  }

  if (data?.user) {
    router.push('/')
    return null
  }

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={async values => {
        try {
          const response = await axios.get<GetProviderType>(`/api/providers?email=${values.email}`)

          if (!response.data) {
            setError("Sorry, we don't have this email in our system, please contact support.")
          }

          const provider = response.data

          if (provider?.authProviderId) {
            provider.authProviderId !== 'google'
              ? setMessage('Redirecting to your SSO provider...')
              : setMessage('Signing in with OAuth...')

            setTimeout(() => {
              // @ts-ignore
              signIn(provider.authProviderId, {
                ...(callbackUrl && { callbackUrl }),
              })
            }, 700)
            return
          }

          console.error('Missing provider for domain', values.email)
          setError('Sorry, we don&apos;t have this email in our system, please contact support.')
        } catch (error) {
          const axiosError = error as AxiosError

          if (axiosError.response && axiosError.response.status === 404) {
            setError("Sorry, we don't have this email in our system, please contact support.")
          } else {
            console.error(axiosError)
          }
        }
      }}
      validationSchema={FormSchema}
    >
      {() => (
        <>
          {message ? (
            <div>{message}</div>
          ) : (
            <div className="mt-6">
              {error && <div className="text-red-500 mb-4 text-sm">{error}</div>}
              <button
                onClick={handlePassageSignIn}
                className="w-full rounded-lg border-2 border-[#D02F97] bg-[#D02F97] px-4 py-2 text-[16px] font-medium text-white hover:bg-[#ba318a] focus:outline-none"
              >
                Employee Sign In
              </button>
              <Link className="" href="/signin?isAdmin=true">
                <button className="mt-4 w-full rounded-lg border-2 border-[#d02f98d4] px-4 py-2 text-[16px] font-semibold text-[#d02f98d4] hover:border-[#ba318a] hover:bg-[#ba318a] hover:text-white focus:outline-none">
                  Admin Sign In
                </button>
              </Link>

              <div className="daisy-divider"></div>
              <div className="flex justify-center">
                <div className="flex justify-center">
                  <Link className="daisy-link daisy-link-primary" href={`/signup?act=${accountId ?? ''}`}>
                    Don&apos;t have an account? Sign Up
                  </Link>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Formik>
  )
}
